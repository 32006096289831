import { graphql, navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";

import Hero from "../../components/Templates/Hero";
import Layout from "../../components/Templates/Layout";
import Link from "../../components/Global/Link";
import Meta from "../../utils/Meta";
import React from "react";
import { contactActions } from "../../store/contact";

const ContactForm = (props) => {
    const { data, blok } = props;

    const pageUrl =
        typeof window !== "undefined" ? window.location.href : "No URL";

    const dispatch = useDispatch();

    let doc;
    if (data) {
        doc = JSON.parse(data?.contact?.content);
    } else {
        doc = blok;
    }
    const openGraph = {
        type: "website",
        title: doc.title,
        description: doc.body,
    };

    const isPurchasingMachines = useSelector(
        (state) => state.contact.isPurchasingMachines
    );
    const isPurchasingPrizes = useSelector(
        (state) => state.contact.isPurchasingPrizes
    );
    const isTalkingManagement = useSelector(
        (state) => state.contact.isTalkingManagement
    );
    const isTalkingTech = useSelector((state) => state.contact.isTalkingTech);
    const isTalkingSpares = useSelector(
        (state) => state.contact.isTalkingSpares
    );

    const toggleIsPurchasingMachinesHandler = () => {
        dispatch(contactActions.setIsPurchasingMachines(!isPurchasingMachines));
    };

    const toggleIsPurchasingPrizesHandler = () => {
        dispatch(contactActions.setIsPurchasingPrizes(!isPurchasingPrizes));
    };

    const toggleManagementSolutionsHandler = () => {
        dispatch(contactActions.setIsTalkingManagement(!isTalkingManagement));
    };

    const toggleSparesHandler = () => {
        dispatch(contactActions.setIsTalkingSpares(!isTalkingSpares));
    };

    const toggleTechHandler = () => {
        dispatch(contactActions.setIsTalkingTech(!isTalkingTech));
    };

    const formSubmitHandler = async (e) => {
        e.preventDefault();

        const {
            comments,
            formLocation,
            emailAddress,
            phoneNumber,
            yourFacility,
            yourName,
        } = e.target.elements;

        await fetch("/.netlify/functions/sendgrid", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_comments: comments.value,
                user_email: emailAddress.value,
                interest_machines: isPurchasingMachines,
                interest_management: isTalkingManagement,
                interest_prizes: isPurchasingPrizes,
                interest_spares: isTalkingSpares,
                interest_tech: isTalkingTech,
                user_phone_number: phoneNumber.value,
                user_facility: yourFacility.value,
                user_name: yourName.value,
                form_location: formLocation.value,
            }),
        });
        navigate("/thanks/");
    };

    return (
        <Layout>
            <Meta openGraph={openGraph} meta={doc.meta} />

            <React.Fragment>
                <Hero
                    title={doc.title}
                    background={doc.image?.filename}
                    colour={doc.colour && doc.colour}
                />

                <section className="w-100 py-5 brand-background-white brand-text-black brand-background-clip border">
                    <div className="container py-5">
                        <div className="row mb-5">
                            <div className="col-12">
                                <h1 className="brand-font text-uppercase text-center brand-text-purple">
                                    Please fill in the form
                                </h1>
                            </div>
                        </div>

                        {/* {!showSuccess ? (
              <Formik
                initialValues={{
                  name: "",
                  phoneNumber: "",
                  email: "",
                  yourFacility: "",
                  comments: "",
                }}
                validationSchema={contactSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    fetch("/contact-us/", {
                      method: "POST",
                      headers: { "Content-Type": "multipart/form-data" },
                      body: new URLSearchParams(values).toString(),
                    })
                      .then(() => setShowSuccess(true))
                      .catch((error) => console.log(error));
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form
                    data-netlify="true"
                    name="contact"
                    action="/"
                    method="POST"
                  >
                    <div className="row gy-4 mb-4">
                      <div className="col-12 col-md-6 position-relative">
                        <Field type="hidden" name="form-name" value="contact" />
                        <label className="form-label brand-text-purple" htmlFor="name">First Name</label>
                        <Field
                          type="text"
                          name="name"
                          className={`form-control brand-form-control ${errors.name && touched.name ? "is-invalid" : ""
                            }`}
                          placeholder="Your name"
                          aria-label="Your name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-tooltip"
                        />
                      </div>
                      <div className="col-12 col-md-6 position-relative">
                        <label className="form-label brand-text-purple" htmlFor="phone">Phone number</label>
                        <Field
                          type="text"
                          name="phone"
                          className={`form-control brand-form-control ${errors.phone && touched.phone ? "is-invalid" : ""
                            }`}
                          placeholder="Your phone number"
                          aria-label="Your phone number"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="invalid-tooltip"
                        />
                      </div>
                    </div>
                    <div className="row gy-4 mb-4">
                      <div className="col-12 col-md-6 position-relative">
                        <label className="form-label brand-text-purple" htmlFor="email">Email address</label>
                        <Field
                          type="email"
                          name="email"
                          className={`form-control brand-form-control ${errors.email && touched.email ? "is-invalid" : ""
                            }`}
                          placeholder="Your email"
                          aria-label="Your email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-tooltip"
                        />
                      </div>
                      <div className="col-12 col-md-6 position-relative">
                        <label className="form-label brand-text-purple" htmlFor="facility">Tell us about your facility</label>
                        <Field
                          type="text"
                          name="facility"
                          className={`form-control brand-form-control ${errors.facility && touched.facility ? "is-invalid" : ""
                            }`}
                          placeholder="Your facility"
                          aria-label="Your facility"
                        />
                        <ErrorMessage
                          name="facility"
                          component="div"
                          className="invalid-tooltip"
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-md-6 position-relative">
                        <label className="form-label brand-text-purple" htmlFor="comments">Comments</label>
                        <Field
                          component="textarea"
                          name="comments"
                          className={`form-control brand-form-control ${errors.comments && touched.comments ? "is-invalid" : ""
                            }`}
                          placeholder="Comments"
                          aria-label="comments"
                          rows="3"
                        />
                        <ErrorMessage
                          name="comments"
                          component="div"
                          className="invalid-tooltip"
                        />
                      </div>
                      <div className="col-12 col-md-6 position-relative">
                        <Field type="hidden" name="isPurchasingMachines" value={isPurchasingMachines} />
                        <Field type="hidden" name="isPurchasingPrizes" value={isPurchasingPrizes} />
                        <Field type="hidden" name="isTalkingManagement" value={isTalkingManagement} />

                        <div className="form-check mb-3">
                          <input
                            className="form-check-input rounded-circle"
                            type="checkbox"
                            checked={isPurchasingMachines}
                            onChange={toggleIsPurchasingMachinesHandler}
                            id="purchasingMachinesCheckbox"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="purchasingMachinesCheckbox"
                          >
                            I'd like to talk about purchasing or leasing amusement
                            machines
                          </label>
                        </div>

                        <div className="form-check mb-3">
                          <input
                            className="form-check-input rounded-circle"
                            type="checkbox"
                            checked={isPurchasingPrizes}
                            onChange={toggleIsPurchasingPrizesHandler}
                            id="purchasingPrizesCheckbox"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="purchasingPrizesCheckbox"
                          >
                            I'd like to talk about purchasing prizes
                          </label>
                        </div>

                        <div className="form-check mb-3">
                          <input
                            className="form-check-input rounded-circle"
                            type="checkbox"
                            checked={isTalkingManagement}
                            onChange={toggleManagementSolutionsHandler}
                            id="isTalkingManagementCheckbox"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isTalkingManagementCheckbox"
                          >
                            I'd like to talk about arcade management solutions
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row text-end">
                      <div className="col-12">
                        <Link
                          button="real"
                          colour="pink"
                          icon={isSubmitting ? "check" : "paper-plane"}
                          disabled={isSubmitting}
                        >
                          Send
                        </Link>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <>
                {doc.thankyou ? (
                  <SmartText className="mb-5">{doc.thankyou}</SmartText>
                ) : (
                  <p>Message sent</p>
                )}
                <p>
                  <Link button icon onClick={() => setShowSuccess(false)}>
                    Send another
                  </Link>
                </p>
              </>
            )} */}

                        <form
                            // data-netlify="true"
                            name="contact"
                            onSubmit={formSubmitHandler}
                            // action="/thanks/"
                            // method="POST"
                        >
                            <input
                                type="hidden"
                                id="formLocation"
                                value={pageUrl}
                            />
                            <input
                                type="hidden"
                                name="form-name"
                                value="contact"
                            />
                            <div className="row justify-content-center align-items-start gy-4">
                                <div className="col col-12 col-md-5">
                                    <label
                                        htmlFor="yourName"
                                        className="form-label brand-text-purple"
                                    >
                                        Your name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="yourName"
                                        name="yourName"
                                    />
                                </div>

                                <div className="col col-12 col-md-5">
                                    <label
                                        htmlFor="phoneNumber"
                                        className="form-label brand-text-purple"
                                    >
                                        Phone number
                                    </label>
                                    <input
                                        type="tel"
                                        className="form-control"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                    />
                                </div>

                                <div className="col col-12 col-md-5">
                                    <label
                                        htmlFor="emailAddress"
                                        className="form-label brand-text-purple"
                                    >
                                        Email address
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="emailAddress"
                                        name="emailAddress"
                                    />
                                </div>

                                <div className="col col-12 col-md-5">
                                    <label
                                        htmlFor="yourFacility"
                                        className="form-label brand-text-purple"
                                    >
                                        Tell us about your facility
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="yourFacility"
                                        name="yourFacility"
                                    />
                                </div>

                                <div className="col col-12 col-md-5">
                                    <label
                                        htmlFor="comments"
                                        className="form-label brand-text-purple"
                                    >
                                        Comments
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="comments"
                                        name="comments"
                                    />
                                </div>

                                <div className="col col-12 col-md-5">
                                    <span className="d-inline-block form-label brand-text-purple">
                                        &nbsp;
                                    </span>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input rounded-circle"
                                            type="checkbox"
                                            checked={isPurchasingMachines}
                                            onChange={
                                                toggleIsPurchasingMachinesHandler
                                            }
                                            id="purchasingMachinesCheckbox"
                                            name="interest_machines"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="purchasingMachinesCheckbox"
                                        >
                                            I'd like to talk about purchasing or
                                            leasing amusement machines
                                        </label>
                                    </div>

                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input rounded-circle"
                                            type="checkbox"
                                            checked={isPurchasingPrizes}
                                            onChange={
                                                toggleIsPurchasingPrizesHandler
                                            }
                                            id="purchasingPrizesCheckbox"
                                            name="interest_prizes"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="purchasingPrizesCheckbox"
                                        >
                                            I'd like to talk about purchasing
                                            prizes
                                        </label>
                                    </div>

                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input rounded-circle"
                                            type="checkbox"
                                            checked={isTalkingManagement}
                                            onChange={
                                                toggleManagementSolutionsHandler
                                            }
                                            id="isTalkingManagementCheckbox"
                                            name="interest_management"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="isTalkingManagementCheckbox"
                                        >
                                            I'd like to talk about arcade
                                            management solutions
                                        </label>
                                    </div>

                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input rounded-circle"
                                            type="checkbox"
                                            checked={isTalkingTech}
                                            onChange={toggleTechHandler}
                                            id="isTalkingTechCheckbox"
                                            name="interest_tech"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="isTalkingTechCheckbox"
                                        >
                                            I'd like to talk about technical
                                            support
                                        </label>
                                    </div>

                                    <div className="form-check mb-5">
                                        <input
                                            className="form-check-input rounded-circle"
                                            type="checkbox"
                                            checked={isTalkingSpares}
                                            onChange={toggleSparesHandler}
                                            id="isTalkingSparesCheckbox"
                                            name="interest_spares"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="isTalkingSparesCheckbox"
                                        >
                                            I'd like to talk about spares /
                                            parts
                                        </label>
                                    </div>

                                    <div>
                                        <Link button="real" colour="pink">
                                            Send
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </React.Fragment>
        </Layout>
    );
};

export default ContactForm;

export const QUERY = graphql`
    query ContactFormPageQuery {
        contact: storyblokEntry(slug: { eq: "contact" }) {
            content
        }
        settings: storyblokEntry(slug: { eq: "settings" }) {
            content
        }
    }
`;
